import React, { useEffect, useCallback, useState } from "react"
import Layout from "./layout.js"
import * as styles from "./list.module.css"
import { faq } from "utils/data"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { getCsListAsync } from "store/modules/postReducer.js"
import api from "apis"
import dompurify from "dompurify"

const List = () => {
  const dispatch = useDispatch()
  const getCsList = useCallback(
    (fre, cate) => dispatch(getCsListAsync(fre, cate)),
    [dispatch]
  )

  useEffect(() => {
    getCsList(true) // fre,cate , 전체중에 자주하는 질문 리스트
    getCsList(false) // 전체보기
  }, [])

  const { csList, freList } = useSelector(
    ({ postReducer: { cs } }) => cs,
    shallowEqual
  )

  // const onClick = async e => {
  //   const result = await api.getfaqDetail(e.target.parentNode.dataset.id)
  //   setOpenAnswer(state => !state)
  //   setAnswer({ data: result, id: e.target.parentNode.dataset.id })
  // }

  const onMenu = async ({ target }) => {
    if (target.className === styles.menuList) return
    const [currentmenu] = document.getElementsByClassName(`${styles.on}`)

    await getCsList(false, target.dataset.id)

    currentmenu.classList.remove(`${styles.on}`)

    target.classList.add(`${styles.on}`)
  }
  return (
    <Layout customStyle={{ display: "none" }}>
      <div className={styles.title}>자주하는 질문</div>

      <div>
        {freList.map(content => (
          <AnswerList content={content} key={content.faq_no} />
        ))}
      </div>
      <div
        className={styles.title}
        style={{ marginTop: "71px", marginBottom: "20px" }}
      >
        FAQ
      </div>
      <div className={styles.menuList} onClick={onMenu}>
        <div className={styles.on}>전체보기</div>
        {Array.from(faq).map(([key, value]) => (
          <div key={value} data-id={`${key}`}>
            {value}
          </div>
        ))}
      </div>
      <div>
        {csList.map(content => (
          <AnswerList content={content} key={content.faq_no} />
        ))}
      </div>
    </Layout>
  )
}

export default List

const AnswerList = ({ content }) => {
  const [open, setOpen] = useState(false)
  const [answer, setAnswer] = useState(null)

  const onClick = async e => {
    const result = await api.getfaqDetail(e.target.parentNode.dataset.id)
    setOpen(state => !state)
    setAnswer({ data: result, id: e.target.parentNode.dataset.id })
  }

  return (
    <div data-id={content.faq_no} onClick={onClick}>
      <div
        className={open ? styles.clickedList : styles.unClickedList}
      >{`Q . ${content.title}`}</div>

      {content.faq_no == answer?.id && open ? (
        <div>
          <div className={styles.anser}>
            A .
            <span
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(answer?.data),
              }}
            />
          </div>
        </div>
      ) : (
        <hr />
      )}
    </div>
  )
}
